<template>

  <b-card no-body>
    <b-tabs card>
      <b-tab title="Project Details">
        <b-form>
          <span v-if="error" class="error">{{ error }}</span>
          <inline-form-row label="Company" _type="b-form-select" :disabled="!this.isNew" :options="computedClients" v-model="project.client" :errors="errors['company']" helpText="Select an existing Company Name"/>
          <inline-form-row label="Name" type="text" :disabled="!this.isNew" v-model="project.name" :errors="errors['name']" helpText="Give the project a useful name - make sure to include (Phase number, year/month, technologies)"/>
          <inline-form-row label="Description" type="text" v-model="project.description" :errors="errors['contact'] " helpText="Explain the project in details - length, location, target, hours"/>
          <inline-form-row label="Purchase Order" type="text" v-model="project.purchase_order" :errors="errors['email']"  helpText="Purchase Order giving by the client or partner"/>
        </b-form>
        <div class="text-right">
          <b-button @click="onSave()" variant="primary" class="m-1">
            Save
          </b-button>   
          <b-button v-if="project.deleted == false" @click="onDelete()" variant="danger" :class="{invisible : isNew}">
            Delete
        </b-button>
        <b-button v-if="project.deleted == true" @click="onActivate()" variant="success" :disabled="isNew">
            Activate
        </b-button>
        <b-button v-if="project.deactivated == false" @click="onDeactivate()" variant="secondary" :class="{invisible : isNew}">
            Deactivate
        </b-button>
        </div>
      </b-tab>
      <b-tab title="Roles">
        <BMSRole :roles="roles" :project="project"/>
      </b-tab>
      <b-tab title="Timesheets" :disabled="isNew">
        <TimesheetTable :items="timesheets" :mapping="mapping" />
      </b-tab>
      <b-tab title="Invoices" :disabled="isNew">
          <InvoiceTable :items="invoices" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>

import { getProject, createProject, deleteProject, updateProject, getTimesheets, getInvoices, getTimesheetInvoiceMapping, activateProject, deactivateProject } from '@/api/projects.api'
import { getConsultingRoles } from '@/api/consulting.api'
import { getAllClients} from '@/api/clients.api'
import InlineFormRow from '@/components/InlineFormRow'
import TimesheetTable from '@/components/TimesheetTable'
import InvoiceTable from '@/components/InvoiceTable'
import BMSRole from '@/components/BMSRole'
import crudMixin from '@/api/crud'
import confirmationModal from '@/api/confirmation'

export default {
    mixins: [crudMixin, confirmationModal],
    data() {
        return {
            id: this.$route.params.id,
            project: {},
            timesheets: [],
            invoices: [],
            mapping: {},
            clients: [],
            roles: []
        }
    },
    computed: {
        isNew: function() {
            return this.id != parseInt(this.id)
        },
        computedClients: function(){
            return this.clients.map((client) => (
                {
                    value: client,
                    text: client.company
                }
            ))
        }
    },
    beforeMount() {
        this.refresh()
        this.getClients();
    },
    components: {
        InlineFormRow,
        TimesheetTable,
        InvoiceTable,
        BMSRole
    },
    methods: {
        refresh: function() {
            if (!this.isNew) {
                this.getProject();
                this.getTimesheets();
                this.getInvoices();
                this.getClients();
                this.getJobs()
                this.call(getTimesheetInvoiceMapping(this.id), (response) => {
                    response.data.mapping.forEach((mappingItem) => {
                        const key = mappingItem[0];
                        this.$set(this.mapping, key, mappingItem);
                    });
                });
            }
        },
        getTimesheets: function() {
            this.call(
                getTimesheets(this.id),
                (response) => {
                    this.timesheets = response.data.time
                }
            )
        },
        getInvoices: function() {
            this.call(
                getInvoices(this.id),
                (response) => {
                    this.invoices = response.data.invoices
                }
            )
        },
        getProject: function() {
            this.call(
                getProject(this.id),
                (response) => {
                    this.project = response.data.project
                }
            )
        },
        getClients: function(){
            this.call(
                getAllClients(),
                (res)=> {
                    this.clients = res.data.clients;
                }
            )
        },
        onDelete: function() {
            // confirmModal(message, okTitle, cancelTitle)
            this.confirmModal().then((result) => {
                if (result){
                    this.deleteObject(
                        deleteProject(this.id),
                        () => {
                            this.$router.push('/projects')
                        }
                    )
                }
            })
        },
        onActivate: function(){
        // confirmModal(message, okTitle, cancelTitle, okVariant {default: danger})
        this.confirmModal("Are you sure?", "ACTIVATE", "CANCEL", "success").then((result) => {
            if (result){
            if (!this.isNew) {
            this.saveObject(
                activateProject(this.id),
                () =>{
                    this.$router.push('/projects')
                }
            )
            }
        }
        })
        },
        onDeactivate: function(){
        // confirmModal(message, okTitle, cancelTitle, okVariant {default: danger})
        this.confirmModal("Are you sure?", "DEACTIVATE", "CANCEL", "success").then((result) => {
            if (result){
            if (!this.isNew) {
            this.saveObject(
                deactivateProject(this.id),
                () =>{
                    this.$router.push('/projects')
                }
            )
            }
        }
        })
        },
        onSave: function() {
            if (this.isNew) {
                var apiCall = createProject(this.project)
            } else {
                apiCall = updateProject(this.id, this.project)
            }
            this.saveObject(apiCall)
        },
        getJobs: function() {
            var params = new URLSearchParams()
            params.append('project_id' , this.id)
            this.call(
                getConsultingRoles(params),
                (res) => {
                    res.data.roles.forEach(role => {
                        this.roles.push(role)
                    })
                }
            )
        }
    }
}
</script>
<style>
  .invisible{
    display: none;
  }
</style>